// Layout
html {
  font-size: 16px;
}

// RFS: Responsive Font Sizes
h1 {
  @include font-size(2rem);
}
h2 {
  @include font-size(1.5rem);
}

// Navigation
.navbar-nav .nav-link {
  // align with demo button on mobile screens
  padding-left: calc(.75rem + 2px);
}
.anchor {
  position: relative;
  display: block;
  height: 0;
  top: -5.5rem;
}

// Buttons
.btn-outline-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

// Colors
.bg-light .fa-li {
  // green Icons on light backgrounds
  color: $FD2;
}

// Big icons
.img-big-icon {
  max-height: 8rem;
}