// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
//$enable-gradients: true;
//$enable-shadows: true;

//$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
//$body-color: #333;
//$body-bg: #fff;
//$border-radius: .4rem;
//$success: #7952b3;


$HT1: rgb(0,12,146);
$HT4b: rgb(186, 211, 119);

$FD2: rgb(163, 177, 39);
$FD2-light: rgb(210, 215, 155);
$FD4: rgb(80, 113, 164);
$FD5: rgb(39, 59, 92);


// adjust border
$border-radius: 2rem;
$border-width: 2px;
$border-color: $FD5;
$border-color-translucent:    rgba($FD5, .3) !default;

// override the default bootstrap 5.2 colors
 $primary: $FD5;
 $secondary: $FD2-light;

 //$success: #198754;
 $success: #157217;
 //$info: #0dcaf0;
 $info: $FD4;
 $warning: #e5a400;
 $danger: #b43b2b;
 $light: rgb(240, 240, 247);
 $dark: #212529;

/* make headlines smaller */
$font-size-base:              1rem; // the bootstrap default
$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base * 1.1 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;

$headings-font-weight: 600;
$btn-font-weight: 600;



// drop the large width container + breakpoints, they don't work well with the
// design of this site
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
);

// Navbar
$navbar-dark-toggler-border-color:  $light;


// Figure (team section)
$figure-caption-font-size:          $font-size-base;
$figure-caption-color:              $primary;

// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
 @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
 @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
 @import "bootstrap/scss/button-group";
 @import "bootstrap/scss/nav";
 @import "bootstrap/scss/navbar"; // Requires nav
 @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Helpers
 @import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";


//
// Custom styles
//

@import "icon-list";
@import "customize";


